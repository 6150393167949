<template>
    <div>
        <router-link
            v-if="prevRoute"
            :to="{ path: prevRoute.path }"
            class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3 mb-4"
        >
            <i class="fas fa-arrow-left mr-2"></i>
            <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
        </router-link>
        <div class="row" v-if="$route.params.extid !== 'null'">
            <div class="col-xxl">
                <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-5 pb-5">
                        <h3 class="card-title align-items-start justify-content-center flex-column">
                            <span class="card-label font-weight-bolder">{{ $t('INTERN.ACTION.TITLE') }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                                $t('INTERN.ACTION.DESC', { number: actions && actions.length ? actions.length : 0 })
                            }}</span>
                        </h3>
                    </div>

                    <div class="card-body p-0">
                        <Skeleton3 v-if="loading" />
                        <ActionTable v-else-if="actions && actions.length > 0" :sortBy="sortBy" />
                        <div v-else-if="!loading && actions && actions.length === 0" class="px-5">
                            <div class="alert alert-info text-center">
                                {{ $t('INTERN.ACTION.NO_ACTION') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-auto">
                    <div class="col-xxl-12">
                        <div v-if="actions.data && actions.data.length > 0">
                            <b-pagination-nav
                                v-if="actions.meta"
                                class="mb-0"
                                align="center"
                                size="lg"
                                use-router
                                v-model="currentPage"
                                :number-of-pages="actions.meta.last_page"
                                :link-gen="linkGen"
                            >
                            </b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="$route.params.plateformeid !== 'null' || plateformeId">
            <div class="col-xxl">
                <div class="card card-custom gutter-b">
                    <div class="card-header border-0 pt-5 pb-5">
                        <h3 class="card-title align-items-start justify-content-center flex-column">
                            <span class="card-label font-weight-bolder">{{ $t('INTERN.ACTION.TITLE_ALT') }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">{{
                                $t('INTERN.ACTION.DESC', { number: externalFormation ? externalFormation.length : 0 })
                            }}</span>
                        </h3>
                        <div class="">
                            <button
                                class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
                                v-b-modal.modal-1
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_1"
                                type="button"
                                :disabled="loading"
                            >
                                Ajouter une formation
                            </button>
                        </div>

                        <b-modal
                            @ok="validation()"
                            @hidden="clearInputs()"
                            @hide="clearInputs()"
                            ref="modal"
                            :ok-disabled="
                                (selectedFormation && selectedFormation.length === 0) ||
                                    date === '' ||
                                    diplome === null ||
                                    new Date(expire) < new Date()
                            "
                            ok-title="Envoyer"
                            cancel-title="Annuler"
                            id="modal-1"
                            title="Nouvelle formation"
                        >
                            <div>
                                <p class="text-primary">Merci de compléter tout les champs</p>
                                <div v-if="options.length !== 0" class="mx-1 mb-5">
                                    <label for="themeSelect" class="required form-label">Thème :</label>
                                    <b-form-select required ref="themeSelect" v-model="selected" :options="options"></b-form-select>
                                </div>
                                <div v-if="options.length === 0 && !loading" class="alert alert-info text-center m-10">
                                    {{ $t('INTERN.FORMATION.NO_FORMATION') }}
                                </div>
                                <div v-if="options.length !== 0">
                                    <div class="mx-1 mb-5">
                                        <label for="formationSelect" class="required form-label">Formation :</label>
                                        <b-form-input required ref="formationSelect" v-model="selectedFormation"></b-form-input>
                                    </div>
                                    <div class="mx-1 mb-5">
                                        <label for="formationSelect" class="required form-label">Date d'obtention :</label>
                                        <b-form-datepicker
                                            v-bind="labels[locale] || {}"
                                            :locale="locale"
                                            :required="true"
                                            ref="dateInput"
                                            id="datepicker-date"
                                            v-model="date"
                                            class="mb-2"
                                            :max="new Date()"
                                            start-weekday="1"
                                        ></b-form-datepicker>
                                    </div>
                                    <div v-if="expire" class="mx-1 mb-5">
                                        <label for="formationSelect" class="required form-label">Date d'expiration :</label>
                                        <b-form-datepicker
                                            :value="expire | expirationDate"
                                            ref="expireInput"
                                            id="datepicker-expires"
                                            v-model="expire"
                                            class="mb-2"
                                            disabled
                                        >
                                        </b-form-datepicker>
                                    </div>
                                    <div v-if="expire && new Date(expire) < new Date()" class="alert alert-primary text-center mt-10">
                                        Vous ne pouvez pas ajouter une formation dont la date d'expiration est passée.
                                    </div>
                                    <div class="mx-1 mb-5">
                                        <label for="fileDiplome" class="required form-label">Diplôme :</label>
                                        <b-form-file
                                            accept=".pdf"
                                            v-model="diplome"
                                            :state="Boolean(diplome)"
                                            placeholder="Choisir un fichier"
                                            drop-placeholder="Glissez un fichier ici ou cliquez pour choisir un fichier"
                                            @input="checkPDFSize(diplome)"
                                        ></b-form-file>
                                        <div v-if="showPdfSizeAlert" class="alert alert-primary text-center mt-10">
                                            Votre fichier dépasse la taille maximale (2Mo).
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-modal>
                    </div>

                    <div class="card-body p-0">
                        <Skeleton3 v-if="loadingExternal" />
                        <ExternalFormationsTable
                            v-else-if="externalFormation && externalFormation.length > 0"
                            :externalFormation="externalFormation"
                        />
                        <div v-else-if="externalFormation && externalFormation.length === 0 && !loadingExternal" class="px-5">
                            <div class="alert alert-info text-center">
                                {{ $t('INTERN.ACTION.NO_ACTION') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExternalFormationsTable from '@/view/pages/intern/widget/ExternalFormationsTable'
import ActionTable from '@/view/pages/action/widget/Table'
import Skeleton3 from '@/view/content/skeleton/Skeleton3'
import moment from 'moment'

import { mapGetters, mapMutations, mapState } from 'vuex'
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module'
import { SET_TOOLBAR, RESET_TOOLBAR } from '@/core/services/store/config.module.js'
import { LIST_INTERN_ACTIONS } from '@/core/services/store/api-orion/action'
import { GET_SOURCE } from '@/core/services/store/api/tableSource'
import { GET_INTERN_ORION } from '@/core/services/store/api-orion/intern'
import { GET_INTERN } from '@/core/services/store/api/intern'
import { GET_FORMATION_USER, SET_FORMATIONS_USER } from '@/core/services/store/api/formation'
import { LIST_THEMES } from '@/core/services/store/api/theme'
import { LIST_CUSTOMERS_WITH_FILLIALE } from '@/core/services/store/api/customer'
import { GET_CLIENT_GROUPE } from '@/core/services/store/api-orion/client'
import store from "@/core/services/store";
import {AUTH_API_ORION} from "@/core/services/store/api-orion/auth";

export default {
    data() {
        return {
            prevRoute: null,
            loading: true,
            loadingExternal: true,
            sortBy: '-start',
            currentPage: this.$route.query.page ? this.$route.query.page : 1,
            selected: null,
            selectedFormation: null,
            options: [],
            formations: [],
            date: '',
            theme: null,
            expire: '',
            type: null,
            diplome: null,
            locale: 'fr',
            labels: {
                fr: {
                    labelPrevDecade: 'Décennie précédente',
                    labelPrevYear: 'Année précédente',
                    labelPrevMonth: 'Mois précédent',
                    labelCurrentMonth: 'Mois actuel',
                    labelNextMonth: 'Mois suivant',
                    labelNextYear: 'Année suivante',
                    labelNextDecade: 'Décennie suivante',
                    labelToday: "Aujourd'hui",
                    labelSelected: 'Sélectionné',
                    labelNoDateSelected: 'Aucune date sélectionnée',
                    labelCalendar: 'Calendrier',
                    labelNav: 'Navigation',
                    labelHelp: 'Aide'
                }
            },
            externalFormation: null,
            showPdfSizeAlert: false,
            plateformeId: null
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    async mounted() {
        this.resetError
        this.setToolbar()
        await this.getIntern()
        await this.checkAccess()
        await this.getActions()
        await this.$store.dispatch(LIST_THEMES).then(response => {
            this.theme = response
            this.options.push({
                text: 'Autre',
                value: null
            })
            response.forEach(theme => {
                this.options.push({
                    text: theme.name,
                    value: theme.id
                })
            })
        })
        .finally(() => {
            this.loading = false
        })
    },
    methods: {
        checkPDFSize(file) {
            if (file) {
                if (file.size < 2000000) {
                    this.showPdfSizeAlert = false
                    return true
                } else {
                    this.diplome = null
                    this.showPdfSizeAlert = true
                    return false
                }
            }
        },
        clearInputs() {
            this.date = ''
            this.expire = ''
            this.type = null
            this.selected = null
            this.selectedFormation = null
            this.diplome = null
        },
        makeToast(variant = null, title = null, message = null) {
            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 3000,
                variant: variant
            })
        },
        validation() {
            let data = new FormData()
            data.append('formation', this.selectedFormation)
            data.append('user_id', this.$route.params.plateformeid ? this.$route.params.plateformeid : this.plateformeId)
            data.append('theme_id', this.selected)
            data.append('date', this.date)
            data.append('expiry_date', this.expire)
            data.append('diplome', this.diplome)
            this.$store
                .dispatch(SET_FORMATIONS_USER, data)
                .then(() => {
                    this.getActions()
                    this.clearInputs()
                    this.makeToast('success', this.$t('INTERN.ACTION.SUCCESS'), this.$t('INTERN.FORMATION.CREATE_OK'))
                })
                .catch(e => {
                    this.makeToast('danger', this.$t('INTERN.ACTION.FAIL'), e.data.error)
                })
        },
        setPageTitle(nom, prenom) {
            this.$store.dispatch(SET_PAGETITLE, `${this.$t('INTERN.ACTION.PAGE_TITLE')} ${nom} ${prenom}`)
        },
        async getIntern() {
            if (this.$route.params.extid && this.$route.params.extid !== 'null') {
                //Si on a le paramètre d'URL extid
                let params = {
                    extname: 'Stagiaires',
                    extid: this.$route.params.extid
                }
                await this.$store.dispatch(GET_SOURCE, { params }).then(async result => {
                    //Si on a un résultat dans la table source
                    if (result && result.tableid) {
                        this.plateformeId = result.tableid

                        //Puis on va chercher le stagiaire sur la plateforme
                        await this.$store
                            .dispatch(GET_INTERN, { id: result.tableid })
                            .then(async result => {
                                this.setPageTitle(result.data.lastname, result.data.firstname)
                            })
                            .catch(error => {
                                if (error.status === 404) {
                                    this.$router.push({ name: '404' })
                                }
                            })
                    } else {
                        //Si on a pas de résultat dans la table source, on va chercher sur Orion
                        await this.$store
                            .dispatch(GET_INTERN_ORION, { id: this.$route.params.extid })
                            .then(async result => {
                                this.setPageTitle(result[0].sta_nom, result[0].sta_prenom)
                            })
                            .catch(error => {
                                if (error.status === 404) {
                                    this.$router.push({ name: '404' })
                                }
                            })
                    }
                })
            } else {
                //Si on a pas le paramètre d'URL extid, on va chercher le stagiaire sur la plateforme
                await this.$store
                    .dispatch(GET_INTERN, { id: this.$route.params.plateformeid })
                    .then(async result => {
                        this.setPageTitle(result.data.lastname, result.data.firstname)
                    })
                    .catch(error => {
                        if (error.status === 404) {
                            this.$router.push({ name: '404' })
                        }
                    })
            }
        },
        async checkAccess() {
            if (!this.$store.getters.currentUser.clientOrion) {
                await store.dispatch(AUTH_API_ORION)
            }
            let clientOrion = this.$store.getters.currentUser.clientOrion.extid
            let customer_id = this.$store.getters.currentUser.customer.id

            if (this.intern && this.intern.data) {
                //Si c'est un stagiaire plateforme
                let internCustomerId = this.intern.data.customer.id
                this.$store.dispatch(LIST_CUSTOMERS_WITH_FILLIALE, customer_id).then(customers => {
                    //foreach customers stock customer.id in variable
                    let customerIds = []
                    customers.forEach(customer => {
                        customerIds.push(customer.id)
                    })
                    //check if internCustomerId not in customerIds
                    if (!customerIds.includes(internCustomerId)) {
                        this.$router.push({ name: '404' })
                    }
                })
            } else {
                //Si c'est un stagiaire Orion
                let internClientId = this.internOrion[0].sta_client
                let params = {
                    id: clientOrion
                }
                this.$store.dispatch(GET_CLIENT_GROUPE, params).then(customers => {
                    //foreach customers stock customer.id in variable
                    let customerIds = []
                    customers.forEach(customer => {
                        customerIds.push(customer.cli_id)
                    })
                    // //check if internCustomerId not in customerIds
                    if (!customerIds.includes(internClientId)) {
                        this.$router.push({ name: '404' })
                    }
                })
            }
        },
        async getActions() {
            if (this.$route.params.extid && this.$route.params.extid !== 'null') {
                await this.$store.dispatch(LIST_INTERN_ACTIONS, { id: this.$route.params.extid }).catch(error => {
                    if (error.status === 404) {
                        this.$router.push({ name: '404' })
                    }
                })
            }
            if (this.$route.params.plateformeid || this.plateformeId) {
                await this.$store
                    .dispatch(GET_FORMATION_USER, {
                        user_id: this.$route.params.plateformeid ? this.$route.params.plateformeid : this.plateformeId
                    })
                    .then(response => {
                        this.externalFormation = []
                        this.loadingExternal = false
                        this.externalFormation = response
                    })
                    .catch(error => {
                        this.loadingExternal = false
                        if (error.status === 404) {
                            this.$router.push({ name: '404' })
                        }
                    })
            }
        },

        setToolbar() {
            this.$store.dispatch(SET_TOOLBAR, this.$t('TIP.ACTION.TABLE'))
        },
        linkGen(pageNum) {
            var query = {}
            Object.assign(query, this.$route.query)
            query.page = pageNum
            return {
                name: this.$route.name,
                params: this.$route.params,
                query: query
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch(RESET_TOOLBAR).then(next)
    },
    components: {
        Skeleton3,
        ActionTable,
        ExternalFormationsTable
    },
    computed: {
        ...mapMutations({
            resetError: 'resetError'
        }),
        ...mapGetters({
            actions: 'listActions',
            intern: 'getIntern',
            internOrion: 'getInternOrion',
            currentUser: 'currentUser'
        }),
        ...mapState({
            error: state => state.action.error
        })
    },
    watch: {
        selected: function() {
            this.formations = []
            this.date = ''
            this.expire = ''
            this.type = null
        },
        date: function(value) {
            //find time in theme whith theme id with this.theme.find()
            if (this.selected !== null) {
                const found = this.theme.find(theme => theme.id === this.selected)
                this.expire = moment(value)
                    .add(found.time, 'months')
                    .format('YYYY-MM-DD')
            }
        }
    },
    filters: {
        expirationDate(value) {
            return moment(value).format('DD/MM/YYYY')
        }
    }
}
</script>

<style>
.custom-file-input:lang(fr) ~ .custom-file-label::after {
    content: 'Parcourir';
}
</style>
