<template>
    <b-table ref="modal" hover :fields="fields" :items="externalFormation" responsive class="m-0 text-center">
        <template #cell(theme)="data">
            {{ data.item.theme !== null ? data.item.theme.name : 'Autre' }}
        </template>
        <template #cell(name)="data">
            {{ data.item.formation }}
        </template>
        <template #cell(date)="data">
            {{ formatDate(data.item.date) }}
        </template>
        <template #cell(date_expiration)="data">
            {{ formatDate(data.item.expiry_date) }}
        </template>
        <template #cell(diplome)="data">
            <div v-if="data.item.diplome">
                <a
                    href="#"
                    class="menu-link"
                    @click="view(data.item.diplome)"
                    v-b-tooltip.hover.auto="'Cliquez pour télécharger le diplôme'"
                >
                    <span class="svg-icon">
                        <inline-svg src="/media/svg/icons/Files/pdf.svg" />
                    </span>
                </a>
            </div>
        </template>
    </b-table>
</template>

<script>
import moment from 'moment'

export default {
    name: 'FormationsTable',
    props: ['externalFormation'],
    data() {
        return {
            fields: [
                { key: 'theme', label: 'Thème', sortable: true },
                { key: 'name', label: 'Nom', sortable: true },
                { key: 'date', label: "Date d'obtention", sortable: true },
                { key: 'date_expiration', label: "Date d'expiration", sortable: true },
                { key: 'diplome', label: 'Attestation / diplôme' }
            ]
        }
    },
    mounted() {
        this.formatExternalFormation()
    },
    updated() {
        this.formatExternalFormation()
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY')
            }
        },
        view(diplome) {
            window.open(diplome.url)
        },
        formatExternalFormation() {
            this.externalFormation.forEach(formation => {
                if (formation.expiry_date !== null && new Date(formation.expiry_date) < new Date()) {
                    formation._rowVariant = 'primary'
                }
            })
            //refresh b-table sinon la ligne rouge des formations périmées disparaît
            this.$refs.modal.refresh()
        }
    }
}
</script>
